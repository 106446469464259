<template>
    <div class="home">
        <div class="banner">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="banner-image">
                            <img src="/assets/banner-img.png" alt="Logo der Wolfsburger Lupe">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="banner-content">
                            <h1 class="do-not-translate">WOLFSBURGER <span>LUPE</span></h1>
                            <h5>Deine Plattform für Bildung, Freizeit und Beratung.</h5>
                            <p class="d-none d-lg-block">Hier findest du fundierte Beratungs-, Freizeit- und Weiterbildungsangebote, Informationen über Kitas und Schulen, Tipps und vieles mehr von kleinen und großen Anbietenden aus Wolfsburg für kleine und große Wolfsburger*innen!</p>
                        </div>
                        <div class="banner-search">
                            <h6>Suche nach passenden Angeboten:</h6>
                            <keyword-search @searchWordChanged="searchWordChanged"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <category-section role="region"/>
        <event-section role="region"/>
        <merkliste-section role="region"/>
        <ratgeber-section role="region"/>

    </div>
</template>

<script>
    export default {
        name: "Home",
        components: {
           KeywordSearch: () => import('@/components/controls/KeywordSearch'),
           CategorySection: () => import('./components/categorySection.vue'),
           MerklisteSection: () => import('./components/merklisteSection.vue'),
           RatgeberSection: () => import('./components/ratgeberSection.vue'),
           EventSection: () => import('./components/eventSection.vue'),
       },
       methods: {
        searchWordChanged(word){
            this.$router.push({ name: 'Search', query: { keyword: word } })
        }
    }
};
</script>